import React,{Component} from "react";
// handle button click of login form
//import { instanceOf } from 'prop-types';
//import { withCookies, Cookies } from "react-cookie"
import Cookies from "universal-cookie"
import NumberFormat from 'react-number-format';
const axios = require("axios");
const cookies=new Cookies();
class Signup extends Component{
    constructor(props){
        super(props);
//           this.state={input:{cnic: '',code:'',ipassword:'',cpassword:''},errors:{}};
           this.state={input:{},errors:{},error:'',errorcss:"text-danger"};
           this.handleChange = this.handleChange.bind(this);
           this.handleSignup = this.handleSignup.bind(this);

        }
	/////CNIC Patteren
    handleChange(event) {
        let input=this.state.input;
        input[event.target.name]=event.target.value;
        this.setState({input});
        
       // this.validate();
    //console.log(this.state.input.cnic);

    }

    handleSignup = (event)=>{
        event.preventDefault();
//            this.props.history.push('/Registered');

        if(this.validate()){
           axios({
               method: 'post',
                url: 'https://thekandnsway.pk/ci/index.php/user/sign_up/',
                data: this.state.input,
                headers: {'Content-Type': 'multipart/form-data'}
                })

                .then((response)=> {
                    //handle success
                    //console.log(response.data);
                    if(response.data.length === "2")
                        this.props.history.push('/Registered');
                    else if(response.data === "1")
                    {
                        this.setState({errorcss: "div-change"})
                        this.setState({error:'User is already registered with this credentials'});
                    }
                    else if(response.data === "0")
                    {
                            this.setState({errorcss: "div-change"});
                            this.setState({error:'Invlaid CNIC or Employee code'});
                    }
                    else
                    {
                        this.setState({errorcss: "div-change"});
                        this.setState({error:'There might be a connection error, registration is not completed. Please try later.'});
                    }
 
                })
                .catch(function (response) {
                    //handle error
                    this.setState({error:response.error});
    
                });            
        }
    }
  
    
    validate(){
        let input = this.state.input;
        let errors = {};
        let isValid = true;
       //For CNIC
        if (!input["cnic"]) {
          isValid = false;
          errors["cnic"] = "Please enter valid CNIC.";
        }
        if (typeof input["cnic"] !== "undefined") {   
            let ccnic = input["cnic"];
            ccnic= ccnic.replace(/ /gm, '');
    
            if (ccnic.length < 15) 
          {
            isValid = false;
            errors["cnic"] = "Please enter valid 13 digits CNIC.";
          }
        }
        //For email
        if (!input["email"]) {
            isValid = false;
            errors["email"] = "Please enter your email Address.";
          }
      
          if (typeof input["email"] !== "undefined") {
              
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["email"])) {
              isValid = false;
              errors["email"] = "Please enter valid email address.";
            }
          }                
        //For Employee code
        if(!input["code"])
        {
            isValid=false;
            errors["code"]="Please enter employee code.";
        }
        if(typeof input["code"] !== "undefined")
        {
            if(input["code"].length < 6)
            {   isValid=false;
                errors["code"]="Please enter valid emplyee code";
            }
        }
        
        //For password
        if(!input["ipassword"])
        {
            isValid=false;
            errors["ipassword"]="Please enter password to set.";
        }
        if(typeof input["ipassword"] !== "undefined")
        {
            if(input["ipassword"].length < 8 && input["ipassword"].length > 20)
            {   isValid=false;
                errors["ipassword"]="Password should be of min 8, max 20 characters long.";
            }
        }
        //For Confirm password
        if(!input["cpassword"]){
            isValid=false;
            errors["cpassword"]="Please enter password to confirm.";
        }
        if(typeof input["cpassword"] !== "undefined"){
            if(input["cpassword"].length < 8 && input["cpassword"].length > 20)
                {
                    isValid=false;
                    errors["cpassword"]="Password should be of min 8, max 20 characters long.";
                }
                if(input["cpassword"] !== input["ipassword"] )
                {
                    isValid=false;
                    errors["cpassword"]="Confirm password does not match.";
                }

        }

        this.setState({
          errors: errors
        });
    
        return isValid;
    }        
    render(){
        
        return(
            <form>
                
                {<h3>Sign up</h3>}
{/*                <div id="error" className="text-danger">{this.state.error}</div>*/}

                <div className="form-group row">
                    <label>National Identity Card (CNIC).</label>
                    <NumberFormat format="#####-#######-#"  id="cnic" name="cnic" className="form-control" required size="14" onBlur={this.handleChange} placeholder="Enter 15 digits CNIC" />

{/*                    <input type="text" id="cnic" name="cnic" className="form-control"  maxLength="15" placeholder="Enter 15 digits CNIC (with -)" onBlur={this.handleChange} value={this.state.input.cnic}></input>*/}
                    <small id="passwordHelp" className="text-danger">{this.state.errors.cnic}</small>
                </div>
                <div className="form-group row">
                    <label>Employee code.</label>
                    <input type="text" name="code" id="code" size="8" className="form-control" placeholder="Enter employee code" onBlur={this.handleChange}/>
                    <small id="passwordHelp" className="text-danger">{this.state.errors.code}</small>

                </div>
                <div className="form-group row">
                    <label>Email address </label>
                    <input type="email" name="email" id="email" className="form-control" placeholder="Enter password to register" onBlur={this.handleChange}/>
                    <small id="passwordHelp" className="text-danger">{this.state.errors.ipassword}</small>
                </div>

                <div className="form-group row">
                    <label>Password </label>
                    <input type="password" name="ipassword" id="ipassword" className="form-control" placeholder="Enter password" onBlur={this.handleChange}/>
                    <small id="passwordHelp" className="text-danger">{this.state.errors.ipassword}</small>
                </div>
                <div className="form-group row">
                    <label>Confirm password</label>
                    <input type="password" name="cpassword" id="cpassword" className="form-control" placeholder="Confirm password" onBlur={this.handleChange}/>
                    <small id="passwordHelp" className="text-danger">{this.state.errors.cpassword}</small>

                </div>
                <div className="form-group">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="customcheck1"/>
                        <label className="custom-control-label" htmlFor="customcheck1">Remember me</label>
                    </div>
                </div>
                <div className={this.state.errorcss}>{this.state.error}</div>
                <button type="submit" className="btn button-primary btn-block" onClick={this.handleSignup} >Submit</button>
               {/* <p className="forgot-password text-right">Forgot <a href="#">Password ?</a></p>*/}
    </form>
        )
    }

}
export default Signup