import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import {BrowserRouter as Router, Route, Switch, Link} from "react-router-dom";
import Login from "./components/login";
import Signup from "./components/signup";
import Registered from "./components/Registered"
function App() {
  return (
<Router>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top">
        <div className="container">
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link" to={"/login"}>Login</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/signup"}>Sign up</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="auth-wrapper">
        <div className="auth-inner">
          <Switch>
{/*}            <Route exact path='/' component={Login} />*/}
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/Registered" component={Registered} />
          </Switch>
        </div>
    </div></Router>  );
}

export default App;
