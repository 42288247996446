//import { get } from "js-cookie";
import React, {Component} from "react";
//import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
//import React from "react";
//import {useCookies} from "react-cookie";
import Cookies from "universal-cookie";
//var CryptoJS = require("crypto-js");
const axios = require("axios");
const cookies=new Cookies();
const pass = React.useRef;
class Login extends Component{
    constructor(props) {
        super();
//        this.state = { input: {email: '',password: ''}, errors: {email:''}, myItems:[] };
        this.state = {input:{}, errors: {email:"",password:""}, myItems:[],emp:"" ,error:'',errorcss:"text-danger"};
        this.myChangeHandler = this.myChangeHandler.bind(this);
        this.handleLogin = this.handleLogin.bind(this);

      }
        

        componentDidMount(){
            if( cookies.get('LoginID'))
            {   cookies.remove('LoginID');
                console.log("cookie removed");
            } // Pacman
        }
            myChangeHandler(event) {
                let input = this.state.input;
                input[event.target.name] = event.target.value;
                this.setState({input});
//                this.validate()

          }

        handleLogin = (event) => {
          event.preventDefault();
          let isValid = true;
          this.setState({
            myItems: []
          });
          console.log(this.state.input["password"]);

          if(this.validate())
          {
//            encoded=this.state.input.email.replace(/\@/g, "-");
  
            /*console.log(cookies.get("LoginID"));*/
/*            axios({
              method: 'get',
              url: 'https://thekandnsway.pk/ci/index.php/user/sign_in/'+ encoded + '/' + this.state.input.password 
              })  */
            axios({
              method: 'post',
              url: 'https://thekandnsway.pk/ci/index.php/user/sign_in/',
              data: this.state.input,
              headers: {'Content-Type': 'multipart/form-data'}
              })  
              .then((res) => {
                this.setState({
                emp: res.data
              });
              if(this.state.emp.length > 1)
              {
                cookies.set('LoginID', this.state.emp, { path: '/' });
                this.props.history.push("/Registered");
              }
              else
              {   let errors={};
                  if(this.state.emp==="0")
                  { 
                    errors["email"] = "Please enter valid email Address.";
                  }
                  else
                    {errors["password"]= "Incorrect Password enetered";
                  }
                  isValid = false;
                  this.setState({
                    errors: errors
                  });
              }
            })

            .catch(function (error) {
                this.setState({error:"Unable to login this time, please try later"});
                this.setState({errorcss:"text-danger"});

            }); 
        }
        return isValid;
      }
        validate(){
            let input = this.state.input;
            let errors = {};
            let isValid = true;
            if (!input["email"]) {
              isValid = false;
              errors["email"] = "Please enter your email Address.";
            }
        
            if (typeof input["email"] !== "undefined") {
                
              var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
              if (!pattern.test(input["email"])) {
                isValid = false;
                errors["email"] = "Please enter valid email address.";
              }
            }        
            /*Password Validation*/
            if (!input["password"]) {
              isValid = false;
              errors["password"] = "Please enter password.";
            }

            this.setState({
              errors: errors
            });
        
            return isValid;
        }        
        render(){

        return(
            <form className="form-horizontal">
            <h3>Login </h3>
            <div className="form-group row">
                <label >Email address</label>
                  <input type="text" name="email" id="email" className="form-control" placeholder="Enter email address" 
                  onChange={this.myChangeHandler}></input>
              
                  <small id="passwordHelp" className="text-danger">
                  {this.state.errors.email}
                  </small>      
                      {/*<div className="text-danger">{this.state.errors.email}</div>*/}
            </div>
            <div className="form-group row">
                <label >Password</label>
                <input type="password" className="form-control" placeholder="Enter Password" name="password" onChange={this.myChangeHandler} ></input>
                {/*<div className="text-danger">{this.state.errors.password}</div></div>*/}
                  <small id="passwordHelp" className="text-danger">
                  {this.state.errors.password}
                  </small>      

            </div>
            <div className="form-group">
                <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="checkbox1"></input>
                    <label className="custom-control-label" htmlFor="checkbox1">Remember me</label>
                </div>
            </div>

            <button type="submit" className="btn button-primary btn-block" onClick={this.handleLogin}>Sing In</button>
            <div className={this.state.errorcss}>{this.state.error}</div>
            <p className="forgot-password text-right">
                Forgot <a href="#">Password</a>
            </p>
            </form>
        );
    }
} 

export default Login;