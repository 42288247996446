import React, {Component} from "react";
import logo from "../images/success.jpg";
import Cookies from "universal-cookie";
const cookies =new Cookies() ;
class Registered extends Component{
    componentDidMount(){
        if( cookies.get('LoginID'))
        {   
            //cookies.remove('LoginID');
            console.log(cookies.get('LoginID'));
        } // Pacman
    }
    render(){
        return(
            <div className="form-group" >
                <span align="center"><h4>You have signed up successfully</h4></span>
                <h3><img src={logo} alt="Logo" /> </h3>
                <div>You can start using this plateform, proceed to sign in page.
                </div>
            </div>
        )
    }
}
export default Registered